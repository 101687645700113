export const HOMII_KEYS = {
    PREBOOKING_KEY: 'homii-prebooking-id',
    TRANSACTION_KEY: 'homii-transaction-id',
    TRANSACTION_CHECKOUT_ID: 'homii-transaction-checkout-id',
    TRANSACTION_CHECKSUM: 'homii-transaction-checksum',
    PAYMENT_GATEWAY: 'homii-payment-gateway',
    QUOTE_PARAMS: 'homii-quote-params',
    PAYMENT_METHOD: 'homii-payment-method',
    PAYMENT_STEP_DATA: 'homii-payment-step-data',
    LOCAL_STORAGE_KEY: 'homii-local-storage',
};
